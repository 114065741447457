.GridDescription {
  border-right: 1px solid #dfe3e7;
}

.BoxPOST {
  background-color: #708195;
}

.BoxGET {
  background-color: #100dafc4;
}

.BoxPATCH {
  background-color: #b32895;
}

.BoxPUT {
  background-color: #c4d624;
}

.BoxDELETE {
  background-color: #be520ad3;
}
